import React from 'react';
import { Drawer, List, NavBar, Icon } from 'antd-mobile';
import styles from './index.module.css';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { openDrawer, closeDrawer } from '@redux/actions';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { path, pathOr } from 'ramda';
import { UserGender } from '../../constants/constant';

const itemMenuHeart = require('@assets/item_menu_heart.png');
const itemMenuHelp = require('@assets/item_menu_help.png');
const itemMenuFooter = require('@assets/item_menu_footer.png');

class DrawerMenu extends React.Component {
  state = {
    open: false
  };
  onOpenChange = (...args) => {
    if (this.props.isDrawerOpen) {
      this.props.closeDrawer();
    } else {
      this.props.openDrawer();
    }
  };

  onClickDatingFlow = () => {
    if (this.props.userGender === UserGender.MALE) {
      window.open('https://www.bachelorapp.net/new-flow-male');
    } else {
      window.open('https://www.bachelorapp.net/new-flow-female');
    }
    this.props.closeDrawer();
  };

  onClickHelp = () => {
    this.props.closeDrawer();
    this.props.history.push('/help');
  };

  render() {
    const { t } = this.props;

    const sidebar = (
      <div className={styles.sidebar}>
        <div className={styles.menuItem} onClick={this.onClickDatingFlow}>
          <img src={itemMenuHeart} alt="" className={styles.menuItemIcon} />
          <div className={styles.menuItemText}>{t('drawer-item-1')}</div>
        </div>
        <div className={styles.menuItem} onClick={this.onClickHelp}>
          <img src={itemMenuHelp} alt="" className={styles.menuItemIcon} />
          <div className={styles.menuItemText}>{t('drawer-item-2')}</div>
        </div>
        <div className={styles.footer}>
          <div className={styles.line} />

          <div className={styles.contentFooter}>
            <div className={styles.leftFooter}>
              <div className={styles.textFooter1}>Bachelor</div>
              <div className={styles.textFooter2}>
                {t('drawer-footer-text')}
              </div>
            </div>
            <div className={styles.rightFooter}>
              <img
                src={itemMenuFooter}
                alt=""
                className={styles.menuItemFooter}
              />
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div style={{ height: '100%', zIndex: 9999999 }}>
        <Drawer
          className="my-drawer"
          style={{ minHeight: document.documentElement.clientHeight }}
          contentStyle={{
            textAlign: 'center',
            height: '100%'
            // zIndex: 100000
          }}
          sidebarStyle={{
            zIndex: 100000,
            height: '100%',
            background: `linear-gradient(186.81deg, rgba(223, 185, 99, 0.63) 20.71%, #B3985A 49.37%, #3A311C 91.3%), rgba(192, 167, 110, 0.5)`,
            backgroundColor: '#C0A76E',
            width: '80%'
          }}
          overlayStyle={{
            zIndex: 2224
          }}
          sidebar={sidebar}
          onOpenChange={this.onOpenChange}
          open={this.props.isDrawerOpen}
        >
          {this.props.children}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isDrawerOpen: state.drawer.isDrawerOpen,
    userGender: pathOr(UserGender.MALE, ['auth', 'userdata', 'gender'], state)
  };
};
export default compose(
  withNamespaces('drawer', { wait: true }),
  withRouter,
  connect(
    mapStateToProps,
    {
      openDrawer: openDrawer,
      closeDrawer: closeDrawer
    }
  )
)(DrawerMenu);
